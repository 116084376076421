<template>
  <div>
    <el-dialog
        :title="type===1?'将向船代发送删除报文，确认删除以下舱单数据吗？':'确认删除以下舱单数据吗？'"
        :visible.sync="deleteDialogVisible"
        width="40%"
        append-to-body
        center
    >
      <el-table :data="deleteData" style="width: 100%" stripe border
                max-height="500"
                :header-cell-style="{background: '#d4d9e1',color: '#606278'}"
      >
        <el-table-column
            prop="billno"
            label="提单号"
            width="200"
        ></el-table-column>
        <el-table-column
            prop="vesselname"
            label="船名"
        ></el-table-column>
        <el-table-column prop="voyageno" label="航次" width="160"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
                <el-button @click="deleteDialogVisible = false">
                    取 消
                </el-button>
                <el-button type="danger" @click="type===1?agentDelete():Delete()" :loading="deleteLoading">删 除</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import {agentDelete, eleDelete} from "@/api/shanghaiManifest";

export default {
  data() {
    return {
      deleteLoading:false,
      deleteDialogVisible: false,
      deleteData: [],
      type: 0,//0:本地数据删除,1:船代舱单删除
    };
  },
  methods: {
    init(list, type = 0) {
      this.deleteDialogVisible = true;
      this.deleteData = list;
      this.type = type;
      console.log('type=='+this.type);
    },
    Delete() {
      const data = this.deleteData.map((x) => {
        return x.id;
      })
      this.deleteLoading = true;
      eleDelete(data)
          .then((res) => {
            if (res.data.status) {
              this.$message.success("删除成功");
              this.$emit("refresh");
            } else {
              this.$message.error(res.data.message);
            }

          })
          .finally(() => {
            this.deleteDialogVisible = false;
            this.deleteLoading = false;
          });
    },
    agentDelete() {
      console.log('agentDelete');
      const data = this.deleteData.map((x) => {
        return x.id;
      })
      this.deleteLoading = true;
      agentDelete( data)
          .then((res) => {
            if (res.data.status) {
              this.$message.success("舱单删除成功");
              this.$emit("refresh");
            } else {
              this.$message.error(res.data.message);
            }

          })
          .finally(() => {
            this.deleteDialogVisible = false;
            this.deleteLoading = false;
          });
    },
  },
};
</script>

<style lang="stylus" scoped></style>
